import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Select} from 'antd';
import NavLink from '../NavLink';
import * as NavUtil from '../../Utils/NavUtil';
import {Facebook} from '@styled-icons/fa-brands/Facebook';
import {Instagram} from '@styled-icons/fa-brands/Instagram';
import {Line} from '@styled-icons/fa-brands/Line';
import {Youtube} from '@styled-icons/fa-brands/Youtube';
const Config = require('../../../data.json');

export default function SiteFooter(props) {
  useOutlet('footerConfig');
  const footerConfig = NavUtil.getFooterConfig(props);
  const navItems = NavUtil.getFooterNavItems(props);
  const [locale, setLocale] = useOutlet('locale');

  return (
    <Wrapper id="rev-SiteFooter">
      <div className="content">
        <img
          className="site-logo"
          src="/images/site-logo.png"
          alt="site footer"
        />

        <div className="lang-selector">
          <Select
            value={locale.lang}
            onChange={(nextLang) => setLocale({lang: nextLang})}
            style={{width: 90, marginLeft: 8}}>
            <Select.Option value="tw">中文</Select.Option>
            <Select.Option value="en">English</Select.Option>
          </Select>
        </div>

        <div className="nav-items">
          {navItems.map((navItem, idx) => {
            return (
              <React.Fragment key={idx}>
                {idx !== 0 && <div className="bar">|</div>}

                <NavLink to={navItem.path}>{navItem.name}</NavLink>
              </React.Fragment>
            );
          })}
        </div>

        <div className="social-items">
          {footerConfig.social_fb && (
            <a href={footerConfig.social_fb} target="_blank" rel="noreferrer">
              <Facebook color={Config.themeColor} size={32} />
            </a>
          )}

          {footerConfig.social_ig && (
            <a href={footerConfig.social_ig} target="_blank" rel="noreferrer">
              <Instagram color={Config.themeColor} size={32} />
            </a>
          )}

          {footerConfig.social_line && (
            <a href={footerConfig.social_line} target="_blank" rel="noreferrer">
              <Line color={Config.themeColor} size={32} />
            </a>
          )}

          {footerConfig.social_youtube && (
            <a
              href={footerConfig.social_youtube}
              target="_blank"
              rel="noreferrer">
              <Youtube color={Config.themeColor} size={32} />
            </a>
          )}
        </div>

        <div className="contact-items">
          <div className="title">Contact Us</div>
          <div className="email">
            <a
              target="_blank"
              href={`mailto:${
                footerConfig.contact_email || 'contact@hishop.com'
              }`}
              rel="noreferrer">
              {footerConfig.contact_email || 'contact@hishop.com'}
            </a>
          </div>
          {footerConfig.contact_phone && (
            <div className="phone">{footerConfig.contact_phone}</div>
          )}
          {footerConfig.contact_addr && (
            <div className="addr">{footerConfig.contact_addr}</div>
          )}
          {footerConfig.contact_time && (
            <div className="time">{footerConfig.contact_time}</div>
          )}
          {footerConfig.company_name && <div>{footerConfig.company_name}</div>}
          {footerConfig.gui_number && <div>統編 {footerConfig.gui_number}</div>}
        </div>

        <div className="copyright">
          {footerConfig.copyright || '2021 HiShop Powered By Revteltech'}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
  box-shadow: 0 -3px 6px 0 rgba(137, 137, 137, 0.16);

  & > .content {
    max-width: var(--contentMaxWidth);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 10px 0;
    }

    & > img.site-logo {
      width: 72px;
      height: 72px;
      object-fit: contain;
    }

    & > .lang-selector {
    }

    & > .nav-items {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      & > *:not(:last-child) {
        margin: 0 5px;
      }
    }

    & > .social-items {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      & > * {
        margin: 0 5px;

        & img {
          width: 32px;
          height: 32px;
          object-fit: contain;
          border-radius: 16px;
          overflow: hidden;
          background-color: #888;
        }
      }
    }

    & > .contact-items {
      align-self: stretch;

      & > * {
        margin: 3px 0;
        font-size: 0.9rem;
        text-align: center;
        color: #666;

        &.title {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 5px;
          color: black;
        }
      }

      &::after {
        display: block;
        content: '';
        height: 1px;
        width: 100%;
        margin-top: 15px;
        background-color: #ccc;
      }
    }

    & > .copyright {
      color: #888;
    }
  }
`;
