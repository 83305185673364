import {css} from 'styled-components';

const revSiteNavBar = `
  #rev-SiteNavBar {
    padding: 0px 16px;
    & > .maxwidth-container > .logo-box > a > img {
      display: none;
    }
    & > .maxwidth-container > .logo-box > a::before {
      content: '';
      display: inline-block;
      background-image: url('/images/hishop-nav-logo.jpeg');
      background-size: 140px 28px;
      width: 140px;
      height: 28px;
      @media (max-width: 480px) {
        background-size: 140px 28px;
        width: 140px;
        height: 28px;
      }
    }
  }
`;

export default css`
  ${revSiteNavBar}

  /* #rev-GlobalSpinner {
  } */

  #rev-SiteNavBar > .maxwidth-container > .nav-round-btn-box {
    display: none;
  }

  #rev-ProductListPage .rev-FilterMenuL2,
  #rev-ProductDetailPage .rev-FilterMenuL2 {
    position: sticky;
    top: 72px;
    margin: 0px 10px;

    & > .header {
      padding: 23px 0px;
      font-size: 1.25rem;

      & > .label {
        display: none;
      }

      &::before {
        content: '產品列表';
        flex: 1;
        font-size: 1.3rem;
      }
    }

    & > .L1 {
      background-color: transparent;
      border-radius: 0px;
      padding: 0px;
      margin-bottom: 0px;
      box-shadow: none;

      & > .header {
        padding: 10px 0;
        border-top: 1px solid #d0d0d0;
        font-size: 1rem;
        color: #000000;
      }

      & > .L1-items {
        border-top: 1px solid #d0d0d0;
        padding-left: 15px;
        overflow: hidden;

        & > .L2 {
          padding: 5px 10px;
        }

        & > .L2 > .header {
          font-size: 0.95rem;
          color: #6f6f6f;
        }

        & > .L2.selected > .header {
          font-size: 0.95rem;
          color: var(--primaryColor);
        }
      }

      &:last-child {
        border-bottom: 1px solid #d0d0d0;
      }
    }
  }

  /* $$__CODEGEN_EXTRA_STYLEDCSS__$$ */
`;
