/* $$__CODEGEN_EXTRA_APPACTION_HEAD__$$ */

const AppActionsOverride = {};

//////////////////////////////////////////////////
// Override existing AppActions
AppActionsOverride.doCustomThing = async (...args) => {
  console.log(args);
};

/* $$__CODEGEN_EXTRA_APPACTION_TAIL__$$ */

export default AppActionsOverride;

//////////////////////////////////////////////////
// Extra custom actions
function sayHello() {
  console.log('hello, this is custom section, custom it');
}

export {sayHello};
