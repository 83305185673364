import * as en from 'rev.sdk.js/Locale/en';

const labels = {
  ...en.labels,
  intro: 'Introduction',
  spec: 'Specification',
  remark: 'Remark',
  share_link: 'Share Link',
  confirm: 'Confirm',
  customer_support: 'Contact',
  customer_support_title: 'About what',
  customer_support_content: 'Detailed description',
  name: 'Name',
  phone: 'Phone',
  email: 'Email',
  updated: 'Updated time',
  next_article: 'Next Article',
  prev_article: 'Previous Article',
  cart: 'Cart',
  cart_add: 'Add To Cart',
  cart_add_error_message_items_limit: '超過購物車購買上限，請分批購買en',
  cart_add_error_message:
    '發生錯誤, 請至個人頁面按下「清除購物車」再重新嘗試en',
  cart_clear: 'Clear Cart',
  cart_clear_success: '清除購物車成功en',
  cart_clear_failure: '清除購物車失敗，請與客服人員聯繫en',
  cart_empty_content: '購物車尚無商品！歡迎選購加入購物車！en',
  buy_now: '立即購買en',
  checkout_info: '寄送資訊en',
  cart_empty: 'Cart Empty',
  sale_start_time: '供應開始時間en',
  sale_end_time: '供應結束時間en',
};

export {labels};
