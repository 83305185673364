import React from 'react';

export default function useLayoutInit(props) {
  const {location, pageContext} = props;
  React.useEffect(() => {
    // trigger only once
  }, []);

  React.useEffect(() => {
    // trigger when location change
  }, [location]);

  return {
    extraElement: null,
  };
}
